import React from "react";
import styled from "styled-components";
import SnippetsCode from "../../components/CodeSnippets";

const SnippetsPage = () => {

  return (
    <Wrapper>
      <SnippetsCode/>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;



export default SnippetsPage;
