import React, { useState } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";

import CopyCode from "../../components/Section/CopyCode";
import {
  Wrapper as ResponseWrapper,
  Container as ResponseContainer
} from "../../components/Section/Responses";


import StandaloneSelect from "../../components/StandaloneSelect";


const SnippetsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      node {
        res {
          node
        }
      }
      php {
        res {
          php
        }
      }
      java {
        res {
          java
        }
      }
      cpp {
        res {
          cpp
        }
      }
      csharp {
        res {
          csharp
        }
      }
      python {
        res {
          python
        }
      }
      ruby {
        res {
          ruby
        }
      }
      scala {
        res {
          scala
        }
      }
      windev {
        res {
          windev
        }
      }
    }
  `);


  const options = [
    { value: "node", label: "NODE" },
    { value: "php", label: "PHP" },
    { value: "java", label: "JAVA" },
    { value: "cpp", label: "CPP" },
    { value: "csharp", label: "CSHARP" },
    { value: "python", label: "PYTHON" },
    { value: "ruby", label: "RUBY" },
    { value: "scala", label: "SCALA" },
    { value: "windev", label: "WINDEV" }
  ];

  const [codeState, setCodeState] = useState("node");

  const snippet = Object.entries(data).find((res) => res[0] === codeState);

  return (
    <Wrapper>
      <Container>
        <StandaloneSelect
          value={{
            label: codeState.toUpperCase(),
            value: codeState
          }}
          options={options}
          onChange={(e) => {
            setCodeState(e.value);
          }}
          isSearchable={false}
        ></StandaloneSelect>
      </Container>

      <OverrideResponseWrapper>
        <ResponseContainer>
          <CopyCode>{snippet[1].res[codeState]}</CopyCode>
        </ResponseContainer>
      </OverrideResponseWrapper>
    </Wrapper>
  );
};

const OverrideResponseWrapper = styled(ResponseWrapper)`
& > div {
  border-radius: 0 0 8px 8px;
}
`;

const Wrapper = styled.div`
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 3%;
  display: flex;
  flex-direction: column;
  height: 750px;
  & > #snippets::-webkit-scrollbar-track {
    background: none !important;
  }
`;

const Container = styled.div`
  display: flex;
  padding-top: 20px;
  padding-left: 10px;
  align-items: left;
  background-color: rgb(36,63,91,0.9);
  border-radius: 8px 8px 0px 0px;
  & > div > div > div  {
    color: #fff !important;
  }
`;


export default SnippetsPage;
